import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubHeroMed from "../components/SubHeroMed"
import UnderHeroText from "../components/UnderHeroText"
import Standard from "../components/Standard"
import Standard2 from "../components/Standard2"
import StandardSand from "../components/StandardSand"
import StandardSand2 from "../components/StandardSand2"
import Testimonial from "../components/Testimonial"
import Devices from "../components/Devices"
import SplitNav from "../components/SplitNav"
import CareersImg from "../images/careers.png"
import Case from "../images/case_study_sample.png"
import Deveedaas from "../images/Deveedaas.png"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"

const caseStudyPage = ({ data }) => {
  // const siteTitle = data.site.siteMetadata.title
  console.log(data)
  return (
    // <Layout location={location} title={siteTitle}>
    <Layout>
      <SEO title="Services | Cider Consulting" />
      <SubHeroMed
        title="This is the project title"
        text="This is the project description, something that will summarize what we did on this project and how it helped the business and the product users"
      />
      <Devices />
      <Standard
        heading="Company"
        title="Strong title about the company and what thay do"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices, eros eget laoreet commodo, erat nisl elementum dui, sed volutpat diam felis vestibulum mi. Suspendisse vel dignissim quam. Fusce id justo dui. Nullam quis elit et nisl pellentesque sagittis vitae id ligula. Morbi"
        flex="flex-row"
        gatsbyImage={data.careers.childImageSharp.fluid}
      />
      <StandardSand
        heading="Goal"
        title="Strong title about the company and what thay do"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices, eros eget laoreet commodo, erat nisl elementum dui, sed volutpat diam felis vestibulum mi. Suspendisse vel dignissim quam. Fusce id justo dui. Nullam quis elit et nisl pellentesque sagittis vitae id ligula. Morbi"
        flex="flex-row-reverse"
        theme="sand"
        gatsbyImage={data.sand.childImageSharp.fluid}
      />
      <Standard2
        heading="Challenge"
        title="Strong title about the company and what thay do"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices, eros eget laoreet commodo, erat nisl elementum dui, sed volutpat diam felis vestibulum mi. Suspendisse vel dignissim quam. Fusce id justo dui. Nullam quis elit et nisl pellentesque sagittis vitae id ligula. Morbi"
        flex="flex-row"
        gatsbyImage={data.careers.childImageSharp.fluid}
      />
      <StandardSand2
        heading="Solution"
        title="Strong title about the company and what thay do"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices, eros eget laoreet commodo, erat nisl elementum dui, sed volutpat diam felis vestibulum mi. Suspendisse vel dignissim quam. Fusce id justo dui. Nullam quis elit et nisl pellentesque sagittis vitae id ligula. Morbi"
        flex="flex-row-reverse"
        theme="sand"
        gatsbyImage={data.sand.childImageSharp.fluid}
      />
      {/* <Fade duration={1300}> */}
        {/* <div className="h-screen">
          <Image fluid={data.fullHeight.childImageSharp.fluid} className="object-cover h-screen w-full" />
        </div> */}
      {/* </Fade> */}
      <Testimonial
        name="Deveedaas Nandi"
        title="CTO at Yahoo"
        gatsbyImage={data.deveedaas.childImageSharp.fluid}
        text="Cider built the first MVP for us which allowed us to win
      numerous FinTech awards and subsequently we were able to acquire over
      4 million users into our apps."
      />
      <SplitNav />
    </Layout>
  )
}

export default caseStudyPage

export const query = graphql`
  query CaseStudyQuery {
    site {
      siteMetadata {
        description
      }
    }
    deveedaas: file(name: { eq: "Deveedaas" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers: file(name: { eq: "careers" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sand: file(name: { eq: "careers2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fullHeight: file(name: { eq: "full" }) {
      childImageSharp {
        fluid(maxWidth: 3080, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
