import React from "react"
import Link from "gatsby-link"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"

class Standard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { marginLeft: "0px" }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("headingStandard")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    xOffSet = xOffSet + "px"
    this.setState({ marginLeft: xOffSet })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  render() {
    const { title, text, image, heading, flex, gatsbyImage } = this.props
    return (
      <div className="overflow-hidden">
        <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
          <h2 className="heading text-160 mb-8 subline font-knockout text-sand -mr-100"
          style={this.state}
          id="headingStandard"
          >
            {heading}
          </h2>
          <div className={`sm:${flex} flex-col-reverse flex`}>
            <div className="flex-1">
              <Fade duration={1300}>
                <h3 className="subheading font-sofia-bold mb-6 text-2xl">
                  {title}
                </h3>
                <p className="text text-read2 text-lg sm:mb-20">{text}</p>
              </Fade>
            </div>
            <Fade duration={1300}>
              <div className="h-80 w-80 flex-1">
                {/* <img src={image} className="pl-20" alt="Projects" /> */}
                <Image fluid={gatsbyImage} className="pl-20 pt-10" alt="Projects" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default Standard
