import React from "react"
import Image from "../images/case_study.png"
import Fade from "react-reveal/Fade"

const Devices = () => {
  return (
    <div className="bg-black">
      <div className="lg:max-w-twelve md:max-w-ten md:mx-auto md:px-16  md:pb-25 pb-15 lg:pt-30 lg:pb-30 sm:px-16">
        <Fade duration={1300}>
          <div className="">
            <img src={Image} alt="case study image" />
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Devices
