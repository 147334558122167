import React from "react"
import Fade from "react-reveal/Fade"
import Bubbles from "../components/Bubbles"



const SubHeroMed = ({ title, text }) => {
  return (
    <>
    <div className="pt-25 bg-black flex flex-col overflow-hidden" style={{ background: "black" }}>
      <div className="mx-auto text-white flex -mt-25 flex-col lg:w-fourteen md:w-ten lg:px-30 sm:px-16 px-5 sm:justify-end justify-center justify-center pr-20 h-screen">
    <Bubbles />
        <h1
          className="lg:text-160s md:text-130 sm:text-100 text-40 text-yellow txt-rotate mt-10 headline lg:mb-35 md:text-25 sm:mb-20 mb-15 -z-10"
          data-period="2000"
          data-rotate='[ "Simple.", "Painless.", "Smooth.", "Pretty.", "Fun!" ]'
          style={{ fontFamily: "Knockout" }}
        >
          {title}
        </h1>
      </div>
        <Fade duration={1300}>
          <div className="max-w-twelve mx-auto justify-between flex flex-col">
            <p
              className="text-base text text-white text-lg mr-10 mb-12 lg:mb-0 sm:w-500 lg:w-690 z-20"
              style={{ color: "#FFFFFF" }}
            >
              {text}
            </p>
          </div>
        </Fade>
    </div>
    </>
  )
}

export default SubHeroMed
