import React from "react"
import Link from "gatsby-link"

const SplitNav = ({ text }) => {
  return (
    <div className="splitNav">
      <div className=" text-center flex justify-center items-center">
        <Link to="/#clients" className="flex-1 escape">
          <div className="lg:h-180 md:h-128 flex items-center justify-center sm:h-25 h-12 lg:text-30 md:text-25 sm:text-20 text font-sofia-med">
          <p className="sm:-ml-10 lg:-ml-15 pt-1 lg:pt-0"><span className="splitArrow hidden sm:inline">←&nbsp;</span> Back to main page</p>
          </div>
        </Link>
        <Link
          to="/case-study"
          className="flex-1 h-full flex items-center bg-darkYellow justify-center escape"
        >
          <div className="lg:h-180 md:h-128 sm:h-25 h-12 flex items-center justify-center lg:text-30 bg-darkYellow md:text-25 sm:text-20 text font-sofia-med">
            <p className="sm:-mr-10 lg:-mr-15 pt-1 lg:pt-0">
              Next Case Study <span className="splitArrow hidden sm:inline">&nbsp;→</span>
            </p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default SplitNav
