import React from "react"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"

class StandardSand extends React.Component {
  constructor(props) {
    super(props)
    this.state = { marginLeft: "0px", color: "#FFF6DD" }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("headingStandardSand2")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    xOffSet = xOffSet + "px"
    this.setState({ marginLeft: xOffSet, color: "#FFF6DD" })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  render() {
    const { title, text, heading, flex, gatsbyImage } = this.props

    return (
      <div className="overflow-hidden bg-sand">
        <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
          <h2
            className="heading text-160 sm:mb-8 mb-6 subline font-knockout text-sand -mr-100"
            style={this.state}
            id="headingStandardSand2"
          >
            {heading}
          </h2>
          <div className={`flex sm:${flex} flex-col-reverse`}>
            <Fade duration={1300}>
              <div className="flex-1">
                <h3 className="subheading font-sofia-bold md:mb-6 mb-4 text-2xl">
                  {title}
                </h3>
                <p className="text text-read2 text-lg sm:mb-20">{text}</p>
              </div>
            </Fade>
            <Fade duration={1300}>
              <div className="h-80 w-80 flex-1 sm:mb-0 mb-6">
                {/* <img src={img} className="pr-20" alt="Projects" /> */}
                <Image fluid={gatsbyImage} className="pr-20 pt-10" alt="Projects" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default StandardSand
